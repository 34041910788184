<template>
  <el-upload
    class="file-uploader"
    drag
    action=""
    :accept="finalAccept"
    :limit="limit"
    :show-file-list="false"
    :multiple="multiple"
    :before-upload="beforeUpload"
    :on-exceed="handleExceed"
    :disabled="disabled"
  >
    <div style="width: 100%; height: 100%; position: relative">
      <div class="uploaderContainer">
        <div class="uploaderContainerTop">
          <img src="@/assets/img/upload_add.svg" alt="" class="icon" />
          <div class="title">点击或拖放上传{{ supportTypeTxt === 'PDF' ? '文件' : '图片' }}</div>
        </div>
        <div class="content">
          <p v-if="$route.name === 'compress-png'">
            支持PNG图片格式，可保留原有透明背景，一次可以处理30张，会员最大支持100M
          </p>
          <p v-else-if="$route.name === 'edit-id'">支持25M以内的JPG、JPEG、PNG、BMP、WEBP格式图片，一次可以处理1张</p>
          <p v-else-if="$route.name === 'remove-watermark'">
            支持15M以内的JPG、JPEG、PNG、BMP、WEBP格式图片，一次可以处理1张
          </p>
          <p v-else-if="$route.name === 'GifCompose'">支持JPEG、JPG、PNG、WEBP、GIF图片格式，最多处理200张</p>
          <p v-else>
            支持{{ supportTypeTxt }}{{ supportTypeTxt === 'PDF' ? '' : '图片' }}格式，一次可以处理{{ limit }}张{{
              supportMaxTxt
            }}
          </p>
          <p class="tips" v-if="$route.name === 'edit-watermark'">
            （你的照片不会被上传，所有操作都在你的浏览器中进行）
          </p>
          <p class="tips" v-else>（为保障您的隐私安全，您上传的文件30分钟后会被自动删除）</p>
        </div>
      </div>
      <div class="dragTips">释放鼠标上传文件</div>
      <button @click.stop="onUploadByPhone" class="mobile-upload-btn">上传手机图像></button>
    </div>
  </el-upload>
</template>
<script>
import { getToken } from '@/utils/token'

export default {
  name: 'UploadFile',
  props: {
    accept: {
      type: String,
      default: '.png,.jpg,.jpeg,.bmp,.gif,.webp'
    },
    limit: {
      type: Number,
      default: 30
    },
    multiple: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      formatTxt: {
        '.png': 'PNG',
        '.jpg': 'JPG',
        '.jpeg': 'JPEG',
        '.bmp': 'BMP',
        '.gif': 'GIF',
        '.webp': 'WEBP',
        '.heic': 'HEIC',
        '.tiff': 'TIFF',
        '.pdf': 'PDF',
        '.svg': 'SVG',
        '.ico': 'ICO',
        '.bitmap': 'BITMAP',
        '.avif': 'AVIF'
      }
    }
  },
  computed: {
    finalAccept() {
      return this.accept + ',' + this.accept.toUpperCase()
    },
    supportTypeTxt() {
      let txt = ''
      let formatArr = this.accept.split(',')
      formatArr.forEach((item, idx) => {
        if (idx === formatArr.length - 1) {
          if (txt.indexOf(this.formatTxt[item]) < 0) {
            txt += this.formatTxt[item]
          }
        } else {
          if (txt.indexOf(this.formatTxt[item]) < 0) {
            txt += this.formatTxt[item] + '、'
          }
        }
      })
      return txt
    },
    supportMaxTxt() {
      let flag = ['clearness-amplification', 'fangda', 'laozhaopian', 'upcolor'].includes(this.$route.name)
      return !flag ? '，会员最大支持100M' : ''
    }
  },
  methods: {
    beforeUpload(file) {
      this.$emit('onSuccess', file)
      return false
    },
    handleExceed(files) {
      this.$emit('onExceed', files)
    },
    onUploadByPhone() {
      const that = this
      if (!getToken()) {
        this.$Login()
        return
      }
      this.$UploadModal({
        config: {
          accept: this.finalAccept
        },
        async onSuccess(data) {
          if (data?.length > 0) {
            const d = data[0]
            const name = d.url?.split('/').pop()
            const blob = await fetch(d?.url).then(response => response.blob())
            const file = new File([blob], name, {
              type: blob.type
            })
            that.$emit('onSuccess', file)
          }
        }
      })
    }
  }
}
</script>
<style scoped lang="less">
@import url('./index.less');
</style>
