import { ImageMagick, initializeImageMagick, MagickFormat } from '@imagemagick/magick-wasm'
/**
 * @description 获取文件类型
 * @param ext
 * @returns {*|string}
 */
export function getTypeByExt(ext) {
  const type = {
    png: 'image/png',
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    gif: 'image/gif',
    bmp: 'image/bmp',
    webp: 'image/webp',
    tif: 'image/tiff',
    tiff: 'image/tiff',
    avif: 'image/avif',
    orf: 'image/ORF',
    svg: 'image/svg+xml',
    ico: 'image/x-icon'
  }
  return type[ext] || 'image/png'
}
export function getFormatTypeByExt(ext) {
  let typeObj = {
    png: MagickFormat.Png,
    jpeg: MagickFormat.Jpeg,
    jpg: MagickFormat.Jpg,
    bmp: MagickFormat.Bmp,
    webp: MagickFormat.Webp,
    gif: MagickFormat.Gif,
    tif: MagickFormat.Tiff,
    tiff: MagickFormat.Tiff,
    avif: MagickFormat.Avif,
    orf: MagickFormat.Orf,
    svg: MagickFormat.Svg,
    heic: MagickFormat.Heic,
    ico: MagickFormat.Ico
  }
  return typeObj[ext]
}
export function imageFormatConvert(file, { to }) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => {
      const arrayBuffer = reader.result // reader.result 是文件内容
      const uint8Array = new Uint8Array(arrayBuffer)
      // const wasmLocation = new URL('@imagemagick/magick-wasm/magick.wasm', import.meta.url).href
      const wasmLocation = 'https://res.yunkun.cn/magick.wasm'
      initializeImageMagick(wasmLocation).then(() => {
        ImageMagick.read(uint8Array, function (image) {
          image.write(getFormatTypeByExt(to) || image.format, data => {
            let blob = new Blob([data], {
              type: getTypeByExt(to)
            })
            resolve(blob)
          })
        })
      })
    }
    reader.readAsArrayBuffer(file)
  })
}

export function heicCovert(file) {
  return new Promise((resolve, reject) => {
    fetch('https://res.yunkun.cn/img_geshicn/js/libheif.wasm')
      .then(res => res.arrayBuffer())
      .then(wasmBinary => {
        const libheif_1 = libheif({ wasmBinary: wasmBinary })
        console.log(libheif_1.heif_get_version())
        const decoder = new libheif_1.HeifDecoder()

        const reader = new FileReader()
        reader.onload = function (e) {
          const buffer = reader.result
          let imageData = decoder.decode(buffer)
          const image = imageData[0]
          const w = image.get_width()
          const h = image.get_height()
          const canvas = document.createElement('canvas')
          canvas.width = w
          canvas.height = h
          const ctx = canvas.getContext('2d')
          imageData = ctx.createImageData(w, h)
          let img_data = imageData.data
          for (let i = 0; i < w * h; i++) {
            img_data[i * 4 + 3] = 255
          }

          image.display(imageData, display_image_data => {
            if (!display_image_data) {
              reject()
              return
            }

            ctx.putImageData(display_image_data, 0, 0)
            canvas.toBlob(blob => {
              resolve(blob)
            }, 'image/jpeg')

            // if (window.requestAnimationFrame) {
            //   let pending_image_data = display_image_data
            //   window.requestAnimationFrame(function () {
            //     if (pending_image_data) {
            //       ctx.putImageData(pending_image_data, 0, 0)
            //       pending_image_data = null
            //     }
            //   })
            // } else {
            //   ctx.putImageData(display_image_data, 0, 0)
            // }
          })
        }
        reader.readAsArrayBuffer(file)
      })
  })
}
